/*===========================
 24. Your-orders-Page
===========================*/

.my-orders-wrap {
  table {
    @media (max-width: 991px) {
      width: 1022px;
    }
    width: 100%;
  }
  form {
    @media (max-width: 991px) {
      overflow: scroll;
      overflow-y: hidden;
    }
  }
  .my-orders-container {
    padding: 40px;
    border-radius: 20px;
    @media (max-width: 991px) {
      padding: 40px;
    }
    @media (max-width: 767px) {
      padding: 30px;
    }
    @media (max-width: 575px) {
      padding: 20px;
    }
  }
}

.main-tab {
  margin-top: 20px;
  justify-content: center;
  border-bottom: 1px solid $border-color;

  .nav-link {
    color: rgba(93, 102, 111, 0.9);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    padding: 10px 40px;
    background: transparent;
    border: none;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 3px;

    @media (max-width: 767px) {
      padding: 10px 10px;
    }

    &.active {
      color: $white;
      background: $theme-primary-color;
    }
  }
}

.my-orders-wrap td,
.my-orders-wrap th {
  width: 10%;
  max-width: 300px;
  border-bottom: 1px solid #f0f0f094;
  text-align: center;
  font-weight: 400;
  color: #414141;
}

.my-orders-wrap th {
  background: #f8f8f8;
  padding: 20px 0;
}

.my-orders-wrap thead {
  background: #fff;
  color: #505050;
  padding: 40px;
}

.my-orders-wrap form {
  margin: auto;
  text-align: center;
  background: #fff;
}

.my-orders-wrap .product {
  width: 15%;
  padding: 20px 0;
}

.my-orders-wrap .product ul {
  display: flex;
  text-align: left;
  padding-left: 30px;
  align-items: center;
  position: relative;

  li {
    img {
      max-width: 60px;
    }

    &.close {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.text-center {
  text-align: center;
}
.card {
  border: 0.5px solid #5c5c5c;
  border-radius: 20px;
}
