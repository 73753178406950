// page-title

.page-title {
  background: $section-bg-color-s3;
  // min-height: 450px;
  padding: 40px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;

  &::before {
    position: absolute;
    right: 0;
    top: -40px;
    width: 100%;
    height: 100%;
    content: "";
    background: url(../../images/page-title-right.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media (max-width: 991px) {
    // min-height: 350px;
  }

  @media (max-width: 575px) {
    // min-height: 250px;
  }

  .breadcumb-wrap {
    @media (max-width: 575px) {
      text-align: center;
    }

    h2 {
      font-size: 70px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px;
      text-transform: capitalize;
      color: $dark-gray;
      margin-bottom: 15px;

      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 575px) {
        justify-content: center;
      }

      li {
        display: inline-block;
        padding: 0px 15px;
        color: $text-color;
        position: relative;
        font-size: 16px;

        i {
          color: $text-color;
          margin-right: 5px;
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          color: $theme-primary-color;
        }

        @media (max-width: 767px) {
          font-size: 18px;
        }

        @media (max-width: 575px) {
          font-size: 16px;
          padding: 0 10px;

          &:first-child {
            padding-left: 0;
          }
        }

        &:after {
          content: ">";
          position: absolute;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
          color: $text-color;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        a {
          color: $text-color;
          font-size: 16px;
          transition: all 0.3s;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
    }
  }
}
