/*--------------------------------------------------------------
22. Home-style-3
--------------------------------------------------------------*/

/* featured-section style-3 */

.featured-section.style-3 {
	background: $theme-primary-color;

	padding: 15px 120px;

	@media(max-width:1599px) {
		padding: 15px 30px;
	}

	@media(max-width:1399px) {
		padding: 15px;
	}

	.featured-item {
		background: none;
		padding: 0;
		display: flex !important;
		text-align: left;
		align-items: center;

		@media(max-width:400px) {
			justify-content: center;
		}

		.icon {
			width: 50px;
			height: 50px;
			line-height: 50px;
			background-color: rgba(255, 255, 255, 0.15);
			text-align: center;
			margin: 0;
			margin-right: 10px;

			i {
				font-size: 30px;
				color: $white;
			}
		}

		.text {
			h2 {
				line-height: 20px;

				a {
					color: $white;
				}
			}

			span {
				color: $white;
			}
		}
	}

	.owl-nav {
		display: none;
	}
}


/* hot-deal-section */

.hot-deal-section.style-2 {
	background: $white;

	.hot-deal-wrap {
		margin-bottom: 20px;
	}

	.offer-banner-item.green-card {
		border-radius: 5px;
		background: #00BCD4;
		padding: 0px 40px;
		justify-content: space-between;

		@media(max-width:575px) {
			padding: 0px 0px;
		}

		.text {
			max-width: 340px;
		}
	}

	.offer-banner-item.yellow-banner {
		.text {
			padding: 48px 30px;

			@media(max-width:1399px) {
				padding: 35px 15px;
			}
		}
	}

}

/* top-selling-s2 */

.top-selling-s2 {
	background: $section-bg-color;

	.horizontal-product-item {
		background: $white;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next {
		position: absolute;
		top: -100px;
		right: 0;
		@include translatingY();
		width: 25px;
		height: 25px;
		line-height: 23px;
		transition: all .3s;
		border-radius: 5px;
		border: 0;
		background: $white;
		z-index: 1;
		font-size: 8px;
		border-radius: 50%;
		box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);

		&:hover {
			background: $theme-primary-color2;
			color: $white;
		}

		@media(max-width:991px) {
			display: none;
		}
	}

	.owl-nav .owl-prev {
		right: 35px;
	}

	.owl-dots {
		display: none;
	}

	@media (max-width:992px) {
		.owl-dots {
			text-align: center;
			bottom: -10px;
			position: relative;
			display: block;

			button {
				width: 10px;
				height: 12px;
				border: 0;
				background: #d4e6f5;
				margin-right: 10px;
				border-radius: 50%;
			}

			.owl-dot.active {
				background-color: $theme-primary-color;
			}
		}

	}
}


/* cta-banner-section3 style-2 */

.cta-banner-section3.style-2 {
	.cta-banner-wrap {
		background: url(../../images/cta/bg.jpg);
		background-size: cover;
		background-position: center;

		.cta-banner.cta-banner-left {
			background: none;

			&:before {
				display: none;
			}
		}

		.cta-banner-right {
			background: $theme-primary-color;
			align-items: flex-end;
			flex-basis: 65%;

			.cta-banner-img {
				width: 60%;
			}

			.small-heading-title {
				padding: 30px 40px 50px;

				@media(max-width:991px) {
					padding: 30px 30px 40px;
				}

				h3 {
					font-size: 40px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;

					@media(max-width:991px) {
						font-size: 30px;
					}

					@media(max-width:575px) {
						font-size: 25px;
					}
				}

				p {
					color: $white;
				}
			}
		}
	}
}

/* blog-section-s3 */

.blog-section-s3 {
	.blog-items {
		margin-bottom: 30px;

		.blog-item {
			.blog-content {
				h2 {
					font-size: 26px;
					font-style: normal;
					font-weight: 700;
					line-height: 36px;
				}
			}
		}

		.col {
			&:nth-child(1) {
				.blog-item {
					display: flex;
					align-items: center;

					@media(max-width:575px) {
						display: block;
					}

					.blog-img {
						flex-basis: 40%;
					}

					.blog-content {
						padding-top: 0;
						padding-left: 20px;
						flex-basis: 60%;

						@media(max-width:575px) {
							padding-top: 20px;
							padding-left: 0;
						}

						h2 {
							font-size: 20px;
							margin-bottom: 15px;
							line-height: 25px;
						}

						p {
							margin-bottom: 20px;
						}
					}

					&:last-child {
						@media(min-width:991px) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

/* offer-features-area style-2 */

.offer-features-area.style-2 {
	background: $theme-primary-color;

	.offer-features-wrap {
		background: $theme-primary-color;

		.features-item {
			background: none;
			border-radius: 5px;
			border: 1px solid rgba(233, 236, 239, 0.15);

			.features-icon i {
				color: $white;
			}

			h2,
			p {
				color: $white;
			}
		}
	}
}


// instagram-section

.instagram-section {

	.container-fluid {
		padding: 0;
	}

	.instagram-item {
		position: relative;

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "\e902";
			border-radius: 5px;
			background: linear-gradient(180deg, rgba(254, 213, 66, 0.80) 0%, rgba(6, 74, 203, 0.80) 100%);
			font-family: 'icomoon';
			color: #fff;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s;
			z-index: 1;
			font-size: 40px;
			transition: all .6s;
			cursor: pointer;

		}

		&:hover {
			&:before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.owl-nav {
		display: none;
	}
}