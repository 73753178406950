/* ===============================
29. Blog Page
 =================================*/

/* blog-page */

.blog-grid-page {
   .blog-section {
      background: $white;

      .blog-items .blog-item {
         background: #F9F9F9;
      }
   }
}

/* blog-page-area */

.blog-page-area {
   .blog-item {
      margin-bottom: 60px;
      padding: 50px;
      background: $section-bg-color-s2;

      &:last-child {
         margin-bottom: 0;
      }

      .blog-content {
         padding-top: 30px;

         ul {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            li {
               font-size: 14px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;

               &+li {
                  margin-left: 20px;
               }

               i {
                  color: $theme-primary-color;
               }

               strong {
                  color: $dark-gray;
                  font-family: $heading-font;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 28px;
               }
            }
         }


         h2 {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            margin-bottom: 20px;

            @media(max-width:767px) {
               font-size: 25px;
            }

            a {
               color: $dark-gray;

               &:hover {
                  color: $theme-primary-color;
               }
            }
         }

         p {
            margin-bottom: 20px;
         }

      }
   }
}

.blog-page-full {
   .blog-img {
      img {
         width: 100%;
      }
   }
}

/* blog-right-sidebar */

.blog-sidebar {

   @media(max-width:991px) {
      margin-top: 50px;
   }

   .widget {
      margin-top: 50px;
      padding: 40px;
      background: $section-bg-color-s2;

      @media(max-width:1399px) {
         padding: 20px;
      }

      @media(max-width:1199px) {
         padding: 15px;
      }

      @media(max-width:1199px) {
         margin-top: 30px;
      }


      h3 {
         font-size: 26px;
         font-style: normal;
         font-weight: 700;
         line-height: 36px;
         text-transform: capitalize;
         margin-bottom: 20px;
         padding-bottom: 20px;
         margin-bottom: 30px;
         border-bottom: 1px solid $border-color;
         position: relative;

         &:before {
            position: absolute;
            left: 0;
            bottom: -1px;
            content: "";
            width: 53px;
            height: 1px;
            background: $theme-primary-color2;
         }

         @media(max-width:1199px) {
            font-size: 30px;
            line-height: 40px;
         }

         @media(max-width:575px) {
            font-size: 22px;
            line-height: 27px;
         }
      }
   }

   /*** search-widget ***/
   .search-widget {
      padding: 30px;
      background: $section-bg-color-s2;

      form div {
         position: relative;
      }

      input {
         background: none;
         border: 1px solid rgba(0, 0, 0, 0.15);
         background: rgba(255, 255, 255, 0.15);
         height: 55px;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
         line-height: 29px;
         padding: 6px 50px 6px 20px;
         border-radius: 0;
         box-shadow: none;
         @include transition-time(0.3s);
      }

      input:focus {
         background-color: $white;
      }

      form button {
         background: transparent;
         width: 55px;
         height: 50px;
         line-height: 50px;
         font-size: 20px;
         font-size: calc-rem-value(20);
         color: $dark-gray;
         border: 0;
         outline: 0;
         position: absolute;
         right: 0;
         top: 0;
         border-radius: 0;

         i {
            img {
               max-width: 20px;
            }
         }
      }
   }

   .blog-right-info {
      p {
         margin-bottom: 0;
      }
   }

   .category-widget {
      ul {
         li {
            &+li {
               margin-top: 20px;
            }

            a {
               color: $dark-gray;
               font-size: 14px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
               position: relative;
               display: block;
               padding: 5px 0;

               &:before {
                  position: absolute;
                  right: 0;
                  top: 12px;
                  content: "\e932";
                  font-family: "icomoon";
                  font-size: 10px;
               }

               i {
                  margin-right: 20px;
                  color: $theme-primary-color;
               }

               &:hover {
                  color: $theme-primary-color;
               }
            }
         }
      }
   }

   .tag-widget {
      ul {
         display: flex;
         flex-wrap: wrap;

         li {
            margin-right: 10px;
            margin-bottom: 10px;

            a {
               color: $dark-gray;
               display: block;
               font-size: 14px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
               padding: 10px 12px;
               position: relative;
               border-radius: 5px;
               border: 1px solid rgba(0, 0, 0, 0.10);
               background: #FFF;

               &:hover {
                  color: $theme-primary-color;
               }
            }
         }
      }
   }

   .best-selling-right-sidebar {
      position: relative;
      z-index: 1;
      background: $theme-primary-color;
      margin-top: 50px;

      @media (max-width:991px) {
         text-align: center;
      }


      &:before {
         position: absolute;
         left: 0;
         bottom: 0;
         width: 100%;
         height: 100%;
         content: "";
         z-index: -1;
         background: url(../../images/best-selling/banner-bg.png);
         background-repeat: no-repeat;
         background-size: cover;
         background-position: center;
      }

      .right-sidebar-text {
         padding: 40px;
         padding-top: 60px;

         .small-heading-title {
            margin-bottom: 20px;

            span {
               border-radius: 0;
               font-size: 16px;
               clip-path: polygon(9% 0, 100% 0, 90% 100%, 0% 100%);
               padding: 5px 30px;
            }

            h3 {
               font-size: 35px;
               font-weight: 700;
               color: $white;
               margin-bottom: 20px;
            }
         }
      }

      .left-sidebar-img {
         padding-right: 20px;
         padding-bottom: 20px;
         text-align: right;
      }

   }

}