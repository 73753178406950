// style.scss
@import "../../sass/helpers/variables";
@import "../../sass/helpers/mixins";
// Center the login form within the dialog
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  .nav-tabs {
    border-bottom: none;
  }
}

.login-form {
  margin-top: 20px;
  background: white;
  padding: 10px 30px;
  border-radius: 18px;
  width: 100%;
  max-width: 400px;
  .input-box {
    display: flex;
    align-items: center;
    gap: 10px;
    input {
      width: 20px;
      margin-bottom: 0;
    }
    label {
      margin-bottom: 0px;
    }
  }
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
  h2 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
    color: #1c263a;
  }
}

.login-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .nav-item {
    flex: 1;
    text-align: center;

    .nav-link {
      display: block;
      padding: 10px;
      cursor: pointer;
      color: #064acb;
      font-weight: bold;
      border: none;
      &.active {
        background-color: #064acb;
        color: white;
        border-radius: 4px;
      }
    }
  }
}

.contact-form {
  margin-top: 20px;
}

.form-field {
  margin-bottom: 10px;
  position: relative;
  label {
    // font-family: "Cormorant";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    color: #1c263a;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    margin-bottom: 0px;
    padding-left: 10px;
    height: 35px;
    border-radius: 5px;
    border: none;
    background: #f2f2f2;
  }
}

.forgot {
  display: block;
  margin: 15px 0;
  text-align: center;
  font-size: 12px;
  color: $theme-primary-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.otp-timer-container {
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    margin: 0px;
    padding: 0px;
    color: #1c263a;
  }
  button {
    color: #1c263a;
    border: none;
    border-radius: 20px;
    background-color: #f2f2f2;
    cursor: pointer;

    &:disabled {
      // Remove the space before `:disabled`
      background-color: #f3f3f3;
      cursor: not-allowed;
    }
  }
}
